import React, { useState, useEffect, useRef } from "react";
import i18n from "../i18n";

const EmptyKittenCard = (props) => {
    const { kitten } = props;

	  return (
	  	<div className="cell  kitten-card-cell large-3 medium-6 small-6 text-center">

			    <div className="kitten-card">
			    	<div className="kitten-card--image" style={{backgroundImage: `url(''), url('/placeholder-circle.png')`}}>
			    	</div>
			     
						<div className="kitten-card--data  no-margin radius-large-bottom">
						  <div className="grid-x align-middle">
						    
						    <div className="cell text-left hide-for-small-only kitten-card--info">
						        <span className="capitalized global-weight-bold">
						          <div className="skeleton-placeholder"></div>
						        </span>
						        
						      	<span className="global-weight-bold kitten-card--age">
							      	
						      	</span>
						    </div>
						    
						    <div className="cell text-left hide-for-small-only kitten-card--info-secondary">
						    	<div className="skeleton-placeholder"></div>
						    </div>

						  </div>
						</div>
					</div>
		  </div>
  );
};

export default EmptyKittenCard;
