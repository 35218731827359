import React, { useState, useEffect, useRef } from 'react';

const QuoteChat = (props) => {
  //////////////////////////
  /// STATE
  //////////////////////////

  // Loading states
  const [submitLoading, setSubmitLoading] = useState(false);

  // Messages
  const parsedContact = props.user;

  const [script, setScript] = useState(props.initial_script);
  // Editor
  const [editorInput, setEditorInput] = useState('');
  const [editorState, setEditorState] = useState('hidden');

  const [currentStep, setCurrentStep] = useState('');
  const [messages, setMessages] = useState([]);

  // Errors and success
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(false);

  // Ref for textarea
  const textareaRef = useRef(null);
  //////////////////////////
  /// USE EFFECTS
  //////////////////////////

  // Start the process on mount
  useEffect(() => {
    if (currentStep === '') {
      setCurrentStep(script[0]);
      setTimeout(() => {
        addMessage(script[0], false);
        setEditorState(script[0].action);
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Move through the script whenever messages change
  useEffect(() => {
    if (!currentStep) return;

    const currentIndex = script.findIndex((item) => item.body === currentStep.body);
    const nextIndex = currentIndex + 1;
    const newStep = script[nextIndex];

    // If script does NOT expect user input, automatically move forward
    if (currentStep.action === 'next') {
      setNewStep(newStep);
    } else if (
      currentStep.action === 'type' ||
      currentStep.action === 'select' ||
      currentStep.action === 'request'
    ) {
      // Check if the last message is from the user (sender: true)
      if (messages[0]?.sender === true) {
        setNewStep(newStep);
      } else {
        //console.log('Waiting for user input');
      }
    }
  }, [messages, script, currentStep]);

  // forces a focus on the textarea so that it's easier for the
  // user to start typing
  useEffect(() => {
    if (editorState === 'type' || editorState === 'request') {
      textareaRef.current && textareaRef.current.focus();
    }
  }, [editorState]);

  const maybeMarkComplete = (step) => {
    // Check if the last script's body matches the current step's body
    const isLastScriptBodyMatch = script.length > 0 && script[script.length - 1].body === currentStep.body;
    // Check if the last message's sender is true (means the user has provided answer)
    const isFirstMessageSenderTrue = messages.length > 0 && messages[0].sender === true;
    // If both conditions are true, call submitComplete()
    if (isLastScriptBodyMatch && isFirstMessageSenderTrue) {
        console.log(isLastScriptBodyMatch && isFirstMessageSenderTrue, 'isLastScriptBodyMatch && isFirstMessageSenderTrue')
        submitComplete();
    }
  }

  const setNewStep = (step) => {
    //if (!step) return;
    maybeMarkComplete(step);

    setTimeout(() => {
      setCurrentStep(step);
      // If the action is request, do not show a bot message; just make a request
      if (step.action !== 'request') {
        addMessage(step, false);
      } else {
        setEditorState('type');
        submit();
      }

      if (step.action !== 'next' && step.action !== 'request') {
        setEditorState(step.action);
      }
    }, 500);
  };

  //////////////////////////
  /// ADD MESSAGES
  //////////////////////////

  const addMessage = (scriptItem, sender) => {
    
    const cachedMessages = messages;
    
    cachedMessages.unshift({
      body: scriptItem.body,
      meta: scriptItem.body,
      sender: sender,
      reference_id: scriptItem.id,
      type: scriptItem.type,

    });

    setMessages(
      Array.from(cachedMessages, (x) => x)
    )

    submitLog();
  };

  const addUserMessage = (message) => {
    setMessages([
      {
        body: message.body,
        meta: message.meta,
        sender: true,
        reference_id: currentStep.id,
      },
      ...messages,
    ]);
  };

  //////////////////////////
  /// MESSAGE BUBBLES
  //////////////////////////

  const messageItem = (message, sender, index) => {
    // For Phoenix-like classes, we’ll use "message [true|false]" or something similar
    const userClass = sender ? 'true' : 'false'; 
    return (
      <div className={`message ${userClass}`} key={index}>
        <div className="message-wrapper">
          <div className="message--bubble">{message}</div>
        </div>
      </div>
    );
  };

  //////////////////////////
  /// SUBMITTING USER INPUT
  //////////////////////////

  const submitMessage = () => {

    // If body or meta is not provided or empty, exit early
    if (!editorInput.body || !editorInput.meta) {
      return;
    }

    if (editorInput !== '') {
      addUserMessage(editorInput);
      setEditorInput('');
    }
    setEditorState('hidden');
  };

  const triggerMessageSubmit = (value) => {
    addUserMessage(value);
    setEditorInput('');
    setEditorState('hidden');
  };

  //////////////////////////
  /// REQUEST
  //////////////////////////

  const submit = () => {
    setSubmitLoading(true);
    const csrf = document.head.querySelector('meta[name="csrf-token"]').content;

    fetch(`/adoption_forms/${props.chat}/followups`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;',
        'X-CSRF-Token': csrf,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((json) => {
            setScript([...script, ...json.followup]);
          });
        } else {
          res.json().then((json) => {
            setSubmitLoading(false);
            setErrors(json.errors);
          });
        }
      })
      .catch(() => {
        setSubmitLoading(false);
      });
  };

  // Logs the messages via PATCH
  const submitLog = () => {
    let csrf = document.head.querySelector('meta[name="csrf-token"]').content;
    setSubmitLoading(true);

    fetch(`/kittens/${props.kitten_id}/adoption_forms/${props.chat}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json;',
        'X-CSRF-Token': csrf,
      },
      body: JSON.stringify({
        adoption_form: {
          data: messages,
        },
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then(() => {
            //console.log('log ok');
          });
        } else {
          res.json().then(() => {
            //console.log('log fail');
          });
        }
      })
      .catch(() => {
        //console.log('log fail');
      });
  };

  // Marks the chat as complete
  const submitComplete = () => {
    setSubmitLoading(true);
    let csrf = document.head.querySelector('meta[name="csrf-token"]').content;

    fetch(`/kittens/${props.kitten_id}/adoption_forms/${props.chat}/complete`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;',
        'X-CSRF-Token': csrf,
      },
      body: JSON.stringify({
        adoption_form: {
          data: messages,
        },
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((json) => {
            window.location.href = `/kittens/${props.kitten_id}/adoption_forms/${props.chat}/sent`;
          });
        } else {
          res.json().then(() => {
            //console.log('complete fail');
          });
        }
      })
      .catch(() => {
        //console.log('complete fail');
      });
  };

  //////////////////////////
  /// RENDER
  //////////////////////////
  console.log(currentStep)
  return (
      <div className="">
        {success ? (
          <div className="chat-success-message">
            <h3>Soumis avec succès</h3>
            <p>
              Notre équipe examinera les informations et vous répondra avec un devis ou une question si
              nécessaire.
            </p>
          </div>
        ) : (
          <div id="adoption_form_chat">

            <div className="chat-header show-for-small-only">
              <div className="chat-header--content">
                <div className="grid-x align-middle">
                  <div className="cell shrink show-for-small-only">
                    
                  </div>
                  <div className="cell shrink">
                    <div className="chat--image chat-header--chat--image"
                      style={{ backgroundImage: `url(${props.img_url})` }}>
                    </div>
                  </div>
                  <div className="cell medium-6 small-8">
                    
                  </div>
                  <div className="cell auto text-right">
                    <div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>


            {/* CHAT MESSAGES */}
            <div className="chat-messages chat-box" id="chat-messages">
              {messages.map((m, idx) => messageItem(m.body, m.sender, idx))}
            </div>

            {/* CHAT EDITOR */}
            <div className="chat-editor relative">
              {/* 
                If you have a "reply_to" feature or status, 
                you can conditionally render a reply-to box here 
              */}

              {editorState === 'select' && (
                <div className="select-options-container">
                  <div className="reply-prompt">Избери:</div>

                    <div
                      className={`select-options ${
                        currentStep?.options?.length > 3 ? 'two-columns' : ''
                      }`}
                    >
                      {currentStep?.options?.map((option, i) => (
                        <div
                          key={i}
                          className="button secondary hollow"
                          onClick={() =>
                            triggerMessageSubmit({ body: option.text, meta: option.value })
                          }
                        >
                          {option.text}
                        </div>
                      ))}
                  </div>
                </div>
              )}

              {/* Editor for typed messages */}
              {(editorState === 'type' || editorState === 'request') && (
                <div id="chat-editor--content">
                  <div className="grid-x">
                    <div className="cell auto">
                      {/* 
                        In Phoenix, you might see contenteditable / phx-hook usage. 
                        We’ll keep a simple textarea for React.
                      */}
                      <textarea
                        className="message_textarea"
                        value={editorInput.body}
                        onChange={(e) => setEditorInput({body: e.target.value, meta: e.target.value})}
                          onKeyDown={(e) => {
                          if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault(); // Предотвратява добавянето на нов ред
                            submitMessage();    // Изпраща съобщението
                          }
                        }}
                        ref={textareaRef}
                      />
                    </div>
                    <div className="cell shrink">
                      <div style={{ paddingLeft: '10px' }}>
                        <button
                          className="button no-margin"
                          id="submit-message"
                          onClick={() => submitMessage()}
                        >
                          <i className="fa-solid fa-paper-plane-top"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
  );
};

export default QuoteChat;
