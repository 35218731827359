import React, { useState, useEffect } from "react";

const DashboardSocialPostTemplateForm = (props) => {
  const [type, setType] = useState("Content::TextImagePostTemplate");
  const [description, setDescription] = useState(props.description);
  const [images, setImages] = useState([]);
  const [creatomateTemplateId, setCreatomateTemplateId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleImageSelection = (id) => {
    console.log(images)
    if (images.includes(id)) {
      // If the string already exists in the list, remove it
      setImages(images.filter(item => item !== id));
    } else {
      // If the string doesn't exist in the list, add it
      setImages([...images, id]);
      setErrorMessage("");
    }
  };

  const fetchTemplate = () => {
    return props.templates.find((t) => t.template_id === creatomateTemplateId);
  };

  const submitPost = () => {
    if (images.length === 0) {
      setErrorMessage("Изберете няколко снимки.");
      return;
    }

    let csrf = document.head.querySelector('meta[name="csrf-token"]').content;
    return fetch(`${props.post_path}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;",
        "X-CSRF-Token": csrf,
      },
      body: JSON.stringify({
        kitten_token: props.kitten.admin_token,
        social_post_template: {
          type: type,
          description: description,
          photos: images,
          creatomate_template_id: creatomateTemplateId,
        },
      }),
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((json) => {
          window.location.href = `${props.redirect_path}${json.template_id}`;
        });
      } else {
        res.json().then((json) => {
          console.log(json);
        });
      }
    });
  };

  return (
    <div>
      {/* Keep this hidden unless needed */}
      <div className="hidden">
        <label>Type</label>
        <select onChange={(e) => setType(e.target.value)}>
          <option>Content::TextImagePostTemplate</option>
          <option>Content::VideoPostTemplate</option>
        </select>
      </div>

      <label>Описание</label>
      <textarea
        defaultValue={description}
        rows="5"
        onChange={(e) => setDescription(e.target.value)}
      ></textarea>

      {type === "Content::VideoPostTemplate" ? (
        <div>
          <label>Template ID</label>
          <select onChange={(e) => setCreatomateTemplateId(e.target.value)}>
            <option>Select</option>
            {props.templates.map((template) => (
              <option value={template.template_id} key={template.template_id}>
                {template.template_id} - p ({template.images_count}) - t (
                {template.text_length})
              </option>
            ))}
          </select>
        </div>
      ) : (
        ""
      )}

      <label>Снимки</label>
      <div className="grid-x grid-margin-x">
        {props.images.map((img) => (
          <div className={`cell post-image-selector small-3 medium-2 relative ${images.includes(img.id)}`} key={img.id}>
            <div className="post-image-selector--wrapper relative">
              <i className="fa-solid fa-circle-check"></i>
              <img
                src={`${img.url}`}
                className="radius"
                onClick={() => handleImageSelection(img.id)}
              />
            </div>
          </div>
        ))}
      </div>

      {/* Display error message if any */}
      {errorMessage && <div className="callout small-padding-mobile mobile-small-font error round">
          <h6>Грешка</h6>
          <ul>
            <li>{errorMessage}</li>
          </ul>
        </div>}

      <button className="button wide" onClick={() => submitPost()}>
        Напред
      </button>
    </div>
  );
};

export default DashboardSocialPostTemplateForm;
