import React, { useState, useEffect, useRef } from "react";

const TranslationForm = (props) => {
  const { translation } = props;
  const [base, setBase] = useState(translation?.base || "");
  const [bg, setBg] = useState(translation?.bg || "");
  const [en, setEn] = useState(translation?.en || "");
  const [de, setDe] = useState(translation?.de || "");
  const [fr, setFr] = useState(translation?.fr || "");
  const [es, setEs] = useState(translation?.es || "");
  const [ae, setAe] = useState(translation?.ae || "");
  
  // New language states
  const [nl, setNl] = useState(translation?.nl || "");
  const [sv, setSv] = useState(translation?.sv || "");
  const [fi, setFi] = useState(translation?.fi || "");
  const [el, setEl] = useState(translation?.el || "");
  const [ro, setRo] = useState(translation?.ro || "");
  const [it, setIt] = useState(translation?.it || "");
  const [no, setNo] = useState(translation?.no || "");
  const [pt, setPt] = useState(translation?.pt || "");
  const [pl, setPl] = useState(translation?.pl || "");
  const [da, setDa] = useState(translation?.da || "");

  const [category, setCategory] = useState(translation?.category || "");

  // References for each textarea
  const baseRef = useRef(null);
  const bgRef = useRef(null);
  const enRef = useRef(null);
  const deRef = useRef(null);
  const frRef = useRef(null);
  const esRef = useRef(null);
  const aeRef = useRef(null);
  
  // New language refs
  const nlRef = useRef(null);
  const svRef = useRef(null);
  const fiRef = useRef(null);
  const elRef = useRef(null);
  const roRef = useRef(null);
  const itRef = useRef(null);
  const noRef = useRef(null);
  const ptRef = useRef(null);
  const plRef = useRef(null);
  const daRef = useRef(null);

  useEffect(() => {
    // Run once on mount to size textareas for any initial content
    autoResizeAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Helper to auto-resize a single textarea
  const autoResize = (textarea) => {
    if (!textarea) return;
    // Calculate the new height, ensuring it's at least 55px
    // If you want to reset to auto each time, uncomment below
    // textarea.style.height = "auto";
    const newHeight = Math.max(textarea.scrollHeight, 55);
    textarea.style.height = `${newHeight}px`;
  };

  // Auto-resize all textareas
  const autoResizeAll = () => {
    [
      baseRef,
      enRef,
      deRef,
      frRef,
      esRef,
      aeRef,
      nlRef,
      svRef,
      fiRef,
      elRef,
      roRef,
      itRef,
      noRef,
      ptRef,
      plRef,
      daRef,
    ].forEach((ref) => {
      autoResize(ref.current);
    });
  };

  // Curried change handler that sets state and resizes
  const handleChange = (setter, ref) => (e) => {
    setter(e.target.value);
    autoResize(ref.current);
  };

  // Single-translation function
  const translate = (target_lang, func) => {
    // 1) Check if en is empty
    if (!en.trim()) {
      alert("Please fill in the EN field before translating.");
      return; // Stop here
    }

    console.log("TRANS");
    const csrf = document.head.querySelector('meta[name="csrf-token"]').content;
    const endpoint = "/admin/translations/translate";
    const method = "POST";

    fetch(endpoint, {
      method: method,
      headers: {
        "Content-Type": "application/json;",
        "X-CSRF-Token": csrf,
      },
      body: JSON.stringify({
        translation: {
          input: en,
          target_lang: target_lang,
        },
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((json) => {
            console.log(json);
            func(json.result);
          });
        } else {
          res.json().then((json) => {
            console.log(json);
          });
        }
      })
      .catch((err) => console.error(err));
  };

  // Multi-translation function
  const translateAll = () => {
    // 2) Check if en is empty
    if (!en.trim()) {
      alert("Please fill in the EN field before translating to ALL.");
      return; // Stop here
    }
    translate("BG", setBg);
    translate("DE", setDe);
    translate("FR", setFr);
    translate("ES", setEs);
    translate("AR", setAe);
    
    // Translate new languages
    translate("NL", setNl);
    translate("SV", setSv);
    translate("FI", setFi);
    translate("EL", setEl);
    translate("RO", setRo);
    translate("IT", setIt);
    translate("NB", setNo);
    translate("PT", setPt);
    translate("PL", setPl);
    translate("DA", setDa);
  };

  const submit = () => {
    const csrf = document.head.querySelector('meta[name="csrf-token"]').content;
    let endpoint = "/admin/translations";
    let method = "POST";

    // If translation has an id, patch
    if (translation?.id) {
      endpoint = `/admin/translations/${translation.id}`;
      method = "PATCH";
    }

    fetch(endpoint, {
      method: method,
      headers: {
        "Content-Type": "application/json;",
        "X-CSRF-Token": csrf,
      },
      body: JSON.stringify({
        translation: {
          base,
          en,
          bg,
          de,
          fr,
          es,
          ae,
          nl,
          sv,
          fi,
          el,
          ro,
          it,
          no,
          pt,
          pl,
          da,
          category
        },
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then(() => {
            window.location.href = "/admin/translations";
          });
        } else {
          res.json().then((json) => {
            console.log(json);
          });
        }
      })
      .catch((err) => console.error(err));
  };

  // Common style for all textareas
  const textareaStyle = {
    width: "100%",
    boxSizing: "border-box",
    overflow: "hidden",
    minHeight: "55px", // Ensures a default height of 55px
  };

  return (
    <div className="grid-x grid-margin-x align-middle">
      {/* BASE */}
      <div className="cell medium-1">
        <label>ID</label>
      </div>
      <div className="cell medium-5">
        <textarea
          ref={baseRef}
          value={base}
          onChange={handleChange(setBase, baseRef)}
          style={textareaStyle}
        />
      </div>
      <div className="cell medium-4">
        <select onChange={(e) => setCategory(e.target.value)}>
          <option>Select</option>
          <option value="ui">UI elem</option>
          <option value="text">Text</option>
          <option value="email">Email</option>
          <option value="message">Message</option>
          <option value="meta">Meta</option>
        </select>
      </div>
      <div className="cell medium-2"></div>

      {/* EN */}
      <div className="cell medium-1">
        <div style={{ width: 30, marginBottom: 20, borderTop: "1px #eee solid" }}>
          {/* Flag for EN */}
          <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-sh" viewBox="0 0 640 480">
            <path fill="#012169" d="M0 0h640v480H0z" />
            <path fill="#FFF" d="m75 0 244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0z" />
            <path fill="#C8102E" d="m424 281 216 159v40L369 281zm-184 20 6 35L54 480H0zM640 0v3L391 191l2-44L590 0zM0 0l239 176h-60L0 42z" />
            <path fill="#FFF" d="M241 0v480h160V0zM0 160v160h640V160z" />
            <path fill="#C8102E" d="M0 193v96h640v-96zM273 0v480h96V0z" />
          </svg>
        </div>
      </div>
      <div className="cell medium-9">
        <textarea
          ref={enRef}
          value={en}
          onChange={handleChange(setEn, enRef)}
          style={textareaStyle}
        />
      </div>
      <div className="cell medium-2">
        <button onClick={translateAll} className="button">
          Translate ALL
        </button>
      </div>
      <div className="cell">
        <hr className="no-margin" />
        <br />
      </div>

      {/* BG */}
      <div className="cell medium-1">
        <div style={{ width: 30, marginBottom: 20, borderTop: "1px #eee solid" }}>
          {/* Flag for BG */}
          <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-bg" viewBox="0 0 640 480">
            <path fill="#fff" d="M0 0h640v160H0z" />
            <path fill="#00966e" d="M0 160h640v160H0z" />
            <path fill="#d62612" d="M0 320h640v160H0z" />
          </svg>
        </div>
      </div>
      <div className="cell medium-9">
        <textarea
          ref={bgRef}
          value={bg}
          onChange={handleChange(setBg, bgRef)}
          style={textareaStyle}
        />
      </div>
      <div className="cell medium-2">
        <button onClick={() => translate("BG", setBg)} className="button secondary small">
          Translate
        </button>
      </div>

      {/* DE */}
      <div className="cell medium-1">
        <div style={{ width: 30, marginBottom: 20, borderTop: "1px #eee solid" }}>
          {/* Flag for DE */}
          <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-de" viewBox="0 0 640 480">
            <path fill="#fc0" d="M0 320h640v160H0z" />
            <path fill="#000001" d="M0 0h640v160H0z" />
            <path fill="red" d="M0 160h640v160H0z" />
          </svg>
        </div>
      </div>
      <div className="cell medium-9">
        <textarea
          ref={deRef}
          value={de}
          onChange={handleChange(setDe, deRef)}
          style={textareaStyle}
        />
      </div>
      <div className="cell medium-2">
        <button onClick={() => translate("DE", setDe)} className="button secondary small">
          Translate
        </button>
      </div>

      {/* FR */}
      <div className="cell medium-1">
        <div style={{ width: 30, marginBottom: 20, borderTop: "1px #eee solid" }}>
          {/* Flag for FR */}
          <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-fr" viewBox="0 0 640 480">
            <path fill="#fff" d="M0 0h640v480H0z" />
            <path fill="#000091" d="M0 0h213.3v480H0z" />
            <path fill="#e1000f" d="M426.7 0H640v480H426.7z" />
          </svg>
        </div>
      </div>
      <div className="cell medium-9">
        <textarea
          ref={frRef}
          value={fr}
          onChange={handleChange(setFr, frRef)}
          style={textareaStyle}
        />
      </div>
      <div className="cell medium-2">
        <button onClick={() => translate("FR", setFr)} className="button secondary small">
          Translate
        </button>
      </div>

      {/* ES */}
      <div className="cell medium-1">
        <div style={{ width: 30, marginBottom: 20, borderTop: "1px #eee solid" }}>
          {/* Flag for ES */}
          <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-es" viewBox="0 0 640 480">
            <path fill="#AA151B" d="M0 0h640v480H0z" />
            <path fill="#F1BF00" d="M0 120h640v240H0z" />
          </svg>
        </div>
      </div>
      <div className="cell medium-9">
        <textarea
          ref={esRef}
          value={es}
          onChange={handleChange(setEs, esRef)}
          style={textareaStyle}
        />
      </div>
      <div className="cell medium-2">
        <button onClick={() => translate("ES", setEs)} className="button secondary small">
          Translate
        </button>
      </div>

      {/* AE */}
      <div className="cell medium-1">
        <div style={{ width: 30, marginBottom: 20, borderTop: "1px #eee solid" }}>
          {/* Flag for AE */}
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
            <rect width="640" height="480" fill="#006233" />
            <path
              fill="#fff"
              d="M320 220c-30 0-55 25-55 55s25 55 55 55 55-25 55-55-25-55-55-55zm0-40 
               a95 95 0 0 1 0 190 95 95 0 0 1 0-190z"
            />
          </svg>
        </div>
      </div>
      <div className="cell medium-9">
        <textarea
          ref={aeRef}
          value={ae}
          onChange={handleChange(setAe, aeRef)}
          style={textareaStyle}
        />
      </div>
      <div className="cell medium-2">
        <button onClick={() => translate("AR", setAe)} className="button secondary small">
          Translate
        </button>
      </div>

      {/* New Languages */}

      {/* NL */}
      <div className="cell medium-1">
        <div style={{ width: 30, marginBottom: 20, borderTop: "1px #eee solid" }}>
          {/* Flag for NL */}
          <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-nl" viewBox="0 0 640 480">
            <path fill="#21468B" d="M0 0h640v480H0z" />
            <path fill="#FFFFFF" d="M0 160h640v160H0z" />
            <path fill="#AE1C28" d="M0 320h640v160H0z" />
          </svg>
        </div>
      </div>
      <div className="cell medium-9">
        <textarea
          ref={nlRef}
          value={nl}
          onChange={handleChange(setNl, nlRef)}
          style={textareaStyle}
        />
      </div>
      <div className="cell medium-2">
        <button onClick={() => translate("NL", setNl)} className="button secondary small">
          Translate
        </button>
      </div>

      {/* SV */}
      <div className="cell medium-1">
        <div style={{ width: 30, marginBottom: 20, borderTop: "1px #eee solid" }}>
          {/* Flag for SV */}
          <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-sv" viewBox="0 0 640 480">
            <path fill="#0052B4" d="M0 0h640v480H0z" />
            <path fill="#FCD116" d="M0 160h640v160H0z" />
            <path fill="#DA121A" d="M0 0l640 240-640 240z" />
          </svg>
        </div>
      </div>
      <div className="cell medium-9">
        <textarea
          ref={svRef}
          value={sv}
          onChange={handleChange(setSv, svRef)}
          style={textareaStyle}
        />
      </div>
      <div className="cell medium-2">
        <button onClick={() => translate("SV", setSv)} className="button secondary small">
          Translate
        </button>
      </div>

      {/* FI */}
      <div className="cell medium-1">
        <div style={{ width: 30, marginBottom: 20, borderTop: "1px #eee solid" }}>
          {/* Flag for FI */}
          <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-fi" viewBox="0 0 640 480">
            <path fill="#fff" d="M0 0h640v480H0z" />
            <path fill="#003580" d="M0 0h640v180H0z" />
            <path fill="#003580" d="M240 0v480h160V0z" />
          </svg>
        </div>
      </div>
      <div className="cell medium-9">
        <textarea
          ref={fiRef}
          value={fi}
          onChange={handleChange(setFi, fiRef)}
          style={textareaStyle}
        />
      </div>
      <div className="cell medium-2">
        <button onClick={() => translate("FI", setFi)} className="button secondary small">
          Translate
        </button>
      </div>

      {/* EL */}
      <div className="cell medium-1">
        <div style={{ width: 30, marginBottom: 20, borderTop: "1px #eee solid" }}>
          {/* Flag for EL */}
          <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-el" viewBox="0 0 640 480">
            <path fill="#0D5EAF" d="M0 0h640v480H0z" />
            <path fill="#FFFFFF" d="M0 0h640v40H0zM0 80h640v40H0zM0 160h640v40H0zM0 240h640v40H0zM0 320h640v40H0zM0 400h640v40H0z" />
            <path fill="#0D5EAF" d="M0 0h40v480H0z" />
          </svg>
        </div>
      </div>
      <div className="cell medium-9">
        <textarea
          ref={elRef}
          value={el}
          onChange={handleChange(setEl, elRef)}
          style={textareaStyle}
        />
      </div>
      <div className="cell medium-2">
        <button onClick={() => translate("EL", setEl)} className="button secondary small">
          Translate
        </button>
      </div>

      {/* RO */}
      <div className="cell medium-1">
        <div style={{ width: 30, marginBottom: 20, borderTop: "1px #eee solid" }}>
          {/* Flag for RO */}
          <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-ro" viewBox="0 0 640 480">
            <path fill="#002B7F" d="M0 0h213.3v480H0z" />
            <path fill="#FCD116" d="M213.3 0h213.3v480H213.3z" />
            <path fill="#CE1126" d="M426.6 0h213.4v480H426.6z" />
          </svg>
        </div>
      </div>
      <div className="cell medium-9">
        <textarea
          ref={roRef}
          value={ro}
          onChange={handleChange(setRo, roRef)}
          style={textareaStyle}
        />
      </div>
      <div className="cell medium-2">
        <button onClick={() => translate("RO", setRo)} className="button secondary small">
          Translate
        </button>
      </div>

      {/* IT */}
      <div className="cell medium-1">
        <div style={{ width: 30, marginBottom: 20, borderTop: "1px #eee solid" }}>
          {/* Flag for IT */}
          <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-it" viewBox="0 0 640 480">
            <path fill="#009246" d="M0 0h213.3v480H0z" />
            <path fill="#FFFFFF" d="M213.3 0h213.4v480H213.3z" />
            <path fill="#CE2B37" d="M426.7 0h213.3v480H426.7z" />
          </svg>
        </div>
      </div>
      <div className="cell medium-9">
        <textarea
          ref={itRef}
          value={it}
          onChange={handleChange(setIt, itRef)}
          style={textareaStyle}
        />
      </div>
      <div className="cell medium-2">
        <button onClick={() => translate("IT", setIt)} className="button secondary small">
          Translate
        </button>
      </div>

      {/* NO */}
      <div className="cell medium-1">
        <div style={{ width: 30, marginBottom: 20, borderTop: "1px #eee solid" }}>
          {/* Flag for NO */}
          <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-no" viewBox="0 0 640 480">
            <path fill="#BA0C2F" d="M0 0h640v480H0z" />
            <path fill="#FFF" d="M0 160h640v160H0z" />
            <path fill="#BA0C2F" d="M0 0h80v480H0zM0 0l640 480M0 480L640 0" />
          </svg>
        </div>
      </div>
      <div className="cell medium-9">
        <textarea
          ref={noRef}
          value={no}
          onChange={handleChange(setNo, noRef)}
          style={textareaStyle}
        />
      </div>
      <div className="cell medium-2">
        <button onClick={() => translate("NB", setNo)} className="button secondary small">
          Translate
        </button>
      </div>

      {/* PT */}
      <div className="cell medium-1">
        <div style={{ width: 30, marginBottom: 20, borderTop: "1px #eee solid" }}>
          {/* Flag for PT */}
          <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-pt" viewBox="0 0 640 480">
            <path fill="#FF0000" d="M0 0h640v480H0z" />
            <path fill="#006600" d="M0 0h320v480H0z" />
            <path
              fill="#FFCC00"
              d="M160 240a80 80 0 1 1-0.001-160.001A80 80 0 0 1 160 240zm-20-60h40v120h-40z"
            />
          </svg>
        </div>
      </div>
      <div className="cell medium-9">
        <textarea
          ref={ptRef}
          value={pt}
          onChange={handleChange(setPt, ptRef)}
          style={textareaStyle}
        />
      </div>
      <div className="cell medium-2">
        <button onClick={() => translate("PT", setPt)} className="button secondary small">
          Translate
        </button>
      </div>

      {/* PL */}
      <div className="cell medium-1">
        <div style={{ width: 30, marginBottom: 20, borderTop: "1px #eee solid" }}>
          {/* Flag for PL */}
          <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-pl" viewBox="0 0 640 480">
            <path fill="#fff" d="M0 0h640v480H0z" />
            <path fill="#DC143C" d="M0 0h640v240H0z" />
          </svg>
        </div>
      </div>
      <div className="cell medium-9">
        <textarea
          ref={plRef}
          value={pl}
          onChange={handleChange(setPl, plRef)}
          style={textareaStyle}
        />
      </div>
      <div className="cell medium-2">
        <button onClick={() => translate("PL", setPl)} className="button secondary small">
          Translate
        </button>
      </div>

      {/* DA */}
      <div className="cell medium-1">
        <div style={{ width: 30, marginBottom: 20, borderTop: "1px #eee solid" }}>
          {/* Flag for DA */}
          <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-da" viewBox="0 0 640 480">
            <path fill="#C60C30" d="M0 0h640v480H0z" />
            <path fill="#FFFFFF" d="M160 0h80v480h-80z" />
          </svg>
        </div>
      </div>
      <div className="cell medium-9">
        <textarea
          ref={daRef}
          value={da}
          onChange={handleChange(setDa, daRef)}
          style={textareaStyle}
        />
      </div>
      <div className="cell medium-2">
        <button onClick={() => translate("DA", setDa)} className="button secondary small">
          Translate
        </button>
      </div>

      <div className="cell">
        <hr className="no-margin" />
        <br />
      </div>

      <div className="cell medium-2 medium-offset-10">
        <button onClick={submit} className="button block large">
          Submit
        </button>
      </div>
    </div>
  );
};

export default TranslationForm;
